<template>
    <el-main slot="el-main">
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="基本配置" name="1"></el-tab-pane>
                <el-tab-pane label="个性签名" name="2"></el-tab-pane>
                <el-tab-pane label="照片展示" name="3"></el-tab-pane>
            </el-tabs>
        </div>
        <el-form class="form" ref="form" :model="form" style="width: 100%" label-width="160px" :rules="rules">
            <div v-show="activeName == 1">
                <div class="basic-info">
                    <div class="content">
                        <el-form-item label="姓名：" class="item" prop="first_name">
                            <el-input v-model="form.first_name" placeholder="请输入" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item label="职位：" class="item" prop="honour">
                            <el-input v-model="form.honour" size="medium" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="微信号：" class="item" prop="wechat">
                            <el-input v-model="form.wechat" size="medium" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="绑定微信手机：" class="item" prop="mobile">
                            <el-input v-model="form.mobile" placeholder="请输入" size="medium"
                                :disabled="!!id && !!form.mobile"></el-input>
                            <p>
                                企业内必须唯一。此号将绑定企业微信，用于微信内登录和接收AI助手消息，一旦绑定不可修改
                            </p>
                        </el-form-item>
                        <el-form-item label="Email：" class="item" prop="email">
                            <el-input v-model="form.email" placeholder="请输入" size="medium"
                                :disabled="!!id && !!form.email"></el-input>
                            <p>企业内必须唯一。一旦绑定不可修改</p>
                        </el-form-item>
                        <el-form-item label="名片展示手机号：" class="item" prop="mobile_show">
                            <el-input v-model="form.mobile_show" placeholder="请输入" size="medium"></el-input>
                            <p>此号将显示在AI名片的联系方式上，可在后台修改</p>
                        </el-form-item>
                        <el-form-item label="地址：" class="item" prop="address">
                            <el-input v-model="form.address" size="medium" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="头像：" class="item" prop="thumb">
                            <ReadyUploadSource @getSource="(val) => (form.thumb = val.path)" :path="form.thumb"
                                @removeThis="() => (form.thumb = '')"></ReadyUploadSource>
                            <p>图片大小为宽度100%，高度任意大小自适应，推荐350x350 像素</p>
                        </el-form-item>
                        <el-form-item label="人气：" class="item" prop="view">
                            <el-input v-model="form.view" placeholder="请输入" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item label="点赞：" class="item" prop="like">
                            <el-input v-model="form.like" placeholder="请输入" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item label="转发：" class="item" prop="relay">
                            <el-input v-model="form.relay" placeholder="请输入" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item label="分享标题内容：" class="item" prop="share_title_cont">
                            <p>默认“您好，我是XXX公司的某某某”</p>
                            <el-input v-model="form.share_title_cont" placeholder="请输入" size="medium"
                                type="textarea"></el-input>
                        </el-form-item>
                        <el-form-item label="名片样式：" class="item" prop="card_style">
                            <el-radio-group v-model="form.card_style">
                                <el-radio label="standard">默认</el-radio>
                                <el-radio label="miter">样式二</el-radio>
                                <el-radio label="black">样式三</el-radio>
                                <el-radio label="blue">样式四</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="goods-spec" v-show="activeName == 2">
                <div class="content">
                    <el-form-item label="个性签名：" class="item" prop="sign">
                        <el-input v-model="form.sign" placeholder="请输入" size="medium"></el-input>
                    </el-form-item>
                    <el-form-item label="视频：" class="item" prop="video">
                        <ReadyUploadSource @getSource="(val) => (form.video = val.path)"
                            @removeThis="() => (form.video = '')" :path="form.video" type="video"></ReadyUploadSource>
                        <p>视频为全屏播放，推荐710x399 分辨率，也可以任意大小</p>
                    </el-form-item>
                    <el-form-item label="视频封面：" class="item" prop="video_poster">
                        <ReadyUploadSource @getSource="(val) => (form.video_poster = val.path)"
                            :path="form.video_poster" @removeThis="() => (form.video_poster = '')"></ReadyUploadSource>
                    </el-form-item>
                </div>
            </div>
            <div class="goods-detail" v-show="activeName == 3">
                <div class="content">
                    <el-form-item label="照片展示标题：" class="item" prop="pic_title">
                        <el-input v-model="form.pic_title" placeholder="请输入" size="medium"></el-input>
                    </el-form-item>
                    <el-form-item label="照片展示内容：" prop="pic_content">
                        <RichText :richTxt="form.pic_content" @soninfo="(val) => (form.pic_content = val)"></RichText>
                        <p>
                            小程序暂时不支持复杂的格式文本，如果发现前台显示空白，请把内容复制到文本文档里中转一下
                        </p>
                    </el-form-item>
                </div>
            </div>
            <Preservation @preservation="handleSubmit">
                <router-link to="/extension/cardManage/cardList" slot="return" v-if="id" style="margin-right: 10px">
                    <el-button>返回</el-button>
                </router-link>
            </Preservation>
        </el-form>
    </el-main>
</template>

<script>
import ReadyUploadSource from "@/components/readyUploadSource";
import RichText from "@/components/richText";
import Preservation from "@/components/preservation";
export default {
    components: {
        ReadyUploadSource,
        RichText,
        Preservation,
    },
    data() {
        return {
            id: "",
            activeName: "1",
            form: {
                attr: "", // 手机号 微信 地址
                card_style: "standard",
                email: "",
                first_name: "",
                honour: "",
                like: "",
                mobile: "",
                mobile_show: "",
                other_attr: "", // 分享打招呼 个性签名 视频 视频海报
                pic_content: "", // 图片富文本
                pic_title: "", // 图片标题';
                relay: "",
                thumb: "",
                view: "",

                wechat: '',
                share_title_cont: "",
                sign: "",
                video: "",
                video_poster: "",
                address: "",
            },

            rules: {
                first_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                honour: [{ required: true, message: '请输入职位', trigger: 'blur' }],
                wechat: [{ required: true, message: '请输入微信号', trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入绑定微信手机', trigger: 'blur' }],
                email: [{ required: true, message: '请输入Email', trigger: 'blur' }],
                mobile_show: [{ required: true, message: '请输入名片展示手机号', trigger: 'blur' }],
                address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
                thumb: [{ required: true, message: '请上传头像', trigger: 'blur' }],
                view: [{ required: true, message: '请输入人气', trigger: 'blur' }],
                like: [{ required: true, message: '请输入点赞', trigger: 'blur' }],
                relay: [{ required: true, message: '请输入转发', trigger: 'blur' }],
                share_title_cont: [{ required: true, message: '请输入分享标题内容', trigger: 'blur' }],
                sign: [{ required: true, message: '请输入个性签名', trigger: 'blur' }],
                video: [{ required: true, message: '请上传视频', trigger: 'blur' }],
                video_poster: [{ required: true, message: '请上传视频封面', trigger: 'blur' }],
                pic_title: [{ required: true, message: '请输入图片展示标题', trigger: 'blur' }],
                pic_content: [{ required: true, message: '请输入图片展示内容', trigger: 'blur' }],
            },
        };
    },
    created() {
        let id = this.$route.query.id;
        if (id) {
            this.id = id;
            this.getDetail();
        }
    },
    methods: {
        //获取商品详情
        getDetail() {
            this.$axios.post(this.$api.cardManage.cardInfo, { id: this.id }).then((res) => {
                if (res.code == 0) {
                    let { result } = res
                    this.form = {
                        card_style: result.card_style,
                        email: result.email,
                        first_name: result.first_name,
                        honour: result.honour,
                        like: result.like,
                        mobile: result.mobile,
                        mobile_show: result.mobile_show,
                        pic_content: result.pic_content, // 图片富文本
                        pic_title: result.pic_title, // 图片标题';
                        relay: result.relay,
                        thumb: result.thumb,
                        view: result.view,
                    }

                    let attr = result.attrs
                    this.form.wechat = attr.items[1].content
                    this.form.address = attr.items[2].content
                    let other_attr = result.other_attrs
                    this.form.share_title_cont = other_attr.share_title_cont
                    this.form.sign = other_attr.sign
                    this.form.video = other_attr.video
                    this.form.video_poster = other_attr.video_poster
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //商品提交
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let url = this.id ? this.$api.cardManage.editCard : this.$api.cardManage.addCard;
                    let form = {
                        card_style: this.form.card_style,
                        email: this.form.email,
                        first_name: this.form.first_name,
                        honour: this.form.honour,
                        like: this.form.like,
                        mobile: this.form.mobile,
                        mobile_show: this.form.mobile_show,
                        pic_content: this.form.pic_content, // 图片富文本
                        pic_title: this.form.pic_title, // 图片标题';
                        relay: this.form.relay,
                        thumb: this.form.thumb,
                        view: this.form.view,
                    };
                    let attr = {
                        items: [
                            {
                                content: form.mobile_show,
                                optips: "拨打",
                                page_url: "people",
                                title: "手机"
                            },
                            {
                                content: this.form.wechat,
                                optips: "复制",
                                page_url: "wechat",
                                title: "微信"
                            },
                            {
                                content: this.form.address,
                                optips: "复制",
                                page_url: "address",
                                title: "地址"
                            }
                        ]
                    }
                    form.attr = JSON.stringify(attr)
                    let other_attr = {
                        share_title_cont: this.form.share_title_cont,
                        sign: this.form.sign,
                        video: this.form.video,
                        video_poster: this.form.video_poster,
                    }
                    form.other_attr = JSON.stringify(other_attr)
                    if (this.id) form.id = this.id
                    this.$axios.post(url, form).then((res) => {
                        if (res.code == 0) {
                            this.$message.success(`${this.id ? "编辑成功" : "添加成功"}`);
                            this.$router.go(-1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    this.$message.error('请填写完整信息');
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    font-size: 14px;
    position: relative;
    padding: 0;
    height: 100%;

    .tabs {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 20px 20px 0 20px;
        z-index: 1000;
        background: #fff;
    }

    .form {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-top: 60px;
        padding: 80px 20px 60px 20px;
        box-sizing: border-box;
    }

    .goods-submit {
        padding-left: 160px;
        margin: 20px 0;
    }

    .goods-detail .item {
        align-items: flex-start !important;
    }

    .basic-info,
    .price-stock,
    .other-info,
    .goods-spec,
    .goods-detail,
    .jd,
    .activity {
        .title {
            line-height: 35px;
            border-bottom: 1px solid #e4e7ed;
        }

        .content {
            padding: 30px 0 0 0;

            .item {
                span {
                    color: #999;
                    padding-left: 20px;
                }

                p {
                    color: #999;
                }

                .el-select,
                .el-input,
                .el-cascader,
                .el-textarea {
                    width: 400px;
                }

                /deep/ .el-textarea__inner {
                    min-height: 40px !important;
                    padding: 8.5px 15px !important;
                }
            }
        }
    }
}
</style>
